import React, { useRef, useState } from 'react';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';

import { useGraphQL, useOnClickOutside } from '../../hooks';

function ServicesGrid() {
  const {
    functionalFitness,
    nxtlvlYoga,
    nxtlvlBoxing,
    nxtlvlLift,
    mobilityLounge,
    infraredSauna,
    sportsMassage,
    chineseMedicine,
    iceBath,
    fuelBar,
    normatecBoots,
    supplementStore,
  } = useGraphQL();
  return (
    <div className="grid bg-black md:grid-cols-2 lg:grid-cols-3">
      <Service
        label="Functional Fitness"
        slug="/services/functional-fitness/"
        image={functionalFitness.childImageSharp.fluid}
      >
        <p>
          nXtLvL offers a boutique industrial-inspired functional fitness
          training area with state-of-the-art equipment, resistance training,
          and functional training spaces that allow you learn new techniques,
          challenge yourself, and reach all your training goals.
        </p>
      </Service>
      <Service
        label="Olympic Lifting & Power Lifting"
        slug="/services/olympic-lifting/"
        image={nxtlvlLift.childImageSharp.fluid}
      >
        <p>
          Lift heavy with proper technique at NXTLVL Performance & Recovery
          Centre’s dedicated Olympic and Power lifting area, using all the
          latest weightlifting, powerlifting, and plyometrics equipment.
        </p>
      </Service>
      <Service
        label="Infrared Sauna"
        slug="/services/infrared-sauna/"
        image={infraredSauna.childImageSharp.fluid}
      >
        <p>
          Our innovative infrared sauna is part of our recovery centre, helping
          your muscles recover from high intensity workout, improve circulation,
          and aid in relaxation.
        </p>
      </Service>
      <Service
        label="Ice Bath & Hydrotherapy"
        slug="/services/ice-bath-hydrotherapy/"
        image={iceBath.childImageSharp.fluid}
      >
        <p>
          nXtLvL Performance & Recovery Centre is the home of the region’s first
          recovery centre. Complete with infrared sauna, ozone filtered ice
          bath, hydrotherapy hot spa and Normatec Compression Boots.
        </p>
      </Service>
      <Service
        label="Normatec Compression Boots"
        slug="/services/compression-boots/"
        image={normatecBoots.childImageSharp.fluid}
      >
        <p>
          Our Normatec 3 compression boots use dynamic air compression to
          advance your wellness, recover faster, improve your training, and
          maximise your performance. Normatec’s patented precision pulse
          technology helps to increase circulation, revive muscles, and reduce
          swelling and has long been the choice of elite athletes around the
          globe and is now available here at nXtLvL for you.
        </p>
      </Service>
      <Service
        label="Chinese Medicine"
        slug="/services/chinese-medicine/"
        image={chineseMedicine.childImageSharp.fluid}
      >
        <p>
          At nXtLvL we understand that overall health and wellbeing goes far
          beyond fitness. Traditional Chinese Medicine fuses aromatic medicine
          with acupuncture, cupping and massage to work on body and mind. With
          special interest in musculoskeletal pain, women's health, pediatrics,
          auto-immune conditions and mental/emotional well-being. It is a
          multidimensional approach and Francine just wants you to be able to
          live your best life & thrive.
        </p>
      </Service>
      <Service
        label="Supplement Store"
        slug="/services/supplement-store/"
        image={supplementStore.childImageSharp.fluid}
      >
        <p>
          nXtLvL Performance & Recovery Centre is proud to be the new home of a
          supplement store. We have sourced some of the most scientifically
          recognized and pure ingredient based brands for all you performance
          and recovery needs. We use these products in our protein shakes and
          smoothies in store for you to purchase at our protein bar.
        </p>
      </Service>
      <Service
        label="Sport Massage"
        slug="/services/sport-massage/"
        image={sportsMassage.childImageSharp.fluid}
      >
        <p>
          As part of our Performance & Recovery Centre, we understand how vital
          recovery is to maintaining fitness and function. NXTLVL offers
          in-house sports massage from qualified massage therapists to help you
          achieve more in the gym and in everyday life.
        </p>
      </Service>
      <Service
        label="Protein/Fuel Bar"
        slug="/services/the-bar/"
        image={fuelBar.childImageSharp.fluid}
      >
        <p>
          Come in and taste some of the amazing products at our protein bar. We
          make smoothies and protein shakes and sell a range of fresh juices,
          hydrogen water and recovery drinks along with coffee for your. nXtLvL
          isn’t just the place to train, it’s now also the place with your
          recovery covered.
        </p>
      </Service>
    </div>
  );
}

function Service({ children, image, label, slug }) {
  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = useRef();

  // State to manage showing or hiding service information
  const [isOpen, setIsOpen] = useState(false);

  // Function to hide service information
  function handleClose() {
    setIsOpen(false);
  }

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, handleClose);

  return (
    <div className="relative h-0 aspect-ratio-square">
      {isOpen ? (
        <div ref={ref} className="absolute inset-0 flex p-2 bg-black">
          <div className="flex flex-col justify-between flex-1 px-4 py-12 overflow-hidden text-center border-2 border-white">
            <div className="relative overflow-y-scroll overscroll-contain">
              <h3 className="font-bold tracking-widest uppercase">{label}</h3>
              <div className="my-4 prose text-white">{children}</div>
            </div>
            <div className="relative">
              <div
                aria-hidden
                className="absolute inset-x-0 top-0 h-8 transform -translate-y-full bg-gradient-to-t from-black"
              />
              <Link
                aria-label={`Learn more about ${label}`}
                to={slug}
                className="inline-block px-8 py-2 mt-4 text-sm tracking-widest uppercase transition duration-300 ease-in-out border-2 border-white hover:bg-white hover:text-black"
              >
                Learn more
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="absolute inset-0 flex bg-black">
          <div className="absolute inset-0 flex">
            <GatsbyImage fluid={image} className="flex-1" />
          </div>
          <div className="relative flex items-center justify-center flex-1 p-4 bg-black bg-opacity-25">
            <button
              type="button"
              onClick={() => setIsOpen(true)}
              className="w-full px-8 py-4 font-bold text-center uppercase transition duration-300 ease-in-out border-2 border-white hover:bg-white hover:text-black focus:bg-white focus:text-black"
            >
              {label}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

Service.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export { ServicesGrid };
