import React from 'react';

import { Link } from 'gatsby';
import {
  Layout,
  SEO,
  NavBar,
  StartFreeTrial,
  TrueProtein,
  Instagram,
  ContactPageForm,
  Map,
} from '../components';
import {
  LevelUpYourFitness,
  EndOfYearPromotion,
  ServicesGrid,
  MeetYourTeam,
} from '../components/home';
import grunge from '../images/background-image-black.jpg';
import { useGraphQL } from '../hooks';

function IndexPage() {
  const { site, deadlift } = useGraphQL();
  return (
    <Layout isHome>
      <SEO
        title={`Forster Gym | ${site.siteMetadata.title} | Local Forster GYM`}
        useTitleTemplate={false}
        description="The latest Forster GYM nXtLvL is where you will meet your fitness goals, whilst keeping training diverse, challenging, and fun! All are welcome at nXtLvL Forster Gym. Sign up now!"
      />
      <div className="relative pointer-events-none">
        <Spacer />
        <div
          style={{ backgroundImage: `url(${grunge})` }}
          className="bg-fixed pointer-events-auto"
        >
          <NavBar />
          {/* <EndOfYearPromotion /> */}
          <div className="">
            <Link
              className="flex justify-center mx-auto mt-12 text-lg font-bold text-center underline md:text-2xl"
              to="/booking"
            >
              To Book A Class - Click Here
            </Link>
            <br />
            <Link
              className="flex justify-center mx-auto mb-12 text-lg font-bold text-center underline md:text-2xl"
              to="/recovery-services"
            >
              To Book Our Recovery Centre Services - Click Here
            </Link>
          </div>
          <LevelUpYourFitness />
          <ServicesGrid />
          <MeetYourTeam />
          <StartFreeTrial image={deadlift.childImageSharp.fluid} />
          <TrueProtein />
          <Instagram />
          <ContactPageForm />
          <Map />
        </div>
      </div>
    </Layout>
  );
}

function Spacer() {
  return (
    <div
      aria-hidden
      className="relative h-0 -mt-40 pointer-events-none aspect-ratio-16/9 bg-gradient-to-t from-black via-transparent"
    >
      <div aria-hidden className="absolute inset-0" />
    </div>
  );
}

export default IndexPage;
