import React from 'react';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import { Heading } from '../heading';
import { useGraphQL } from '../../hooks';

function LevelUpYourFitness() {
  const { allefVinicius } = useGraphQL();
  return (
    <article className="relative flex flex-col justify-between overflow-hidden">
      <div className="absolute inset-0 flex">
        <GatsbyImage
          fluid={allefVinicius.childImageSharp.fluid}
          className="flex-1"
        />
        <div className="absolute inset-0 bg-black bg-opacity-75 pointer-events-none bg-gradient-to-t from-black via-transparent to-black sm:bg-transparent" />
      </div>
      <div className="relative px-4 py-12 sm:px-6 lg:px-8 text-shadow">
        <Heading eyebrowText="your fitness and recovery" tag="h1" reverse>
          Level up{' '}
        </Heading>
        <div className="mx-auto mt-12 prose text-center text-white">
          <p>
            nXtLvL Performance & Recovery Centre is your ultimate fitness,
            recovery and rejuvenation destination, catering for all ages and
            fitness levels in a classy, fun, friendly, and functional
            environment.
          </p>
          <p>
            Over three boutique, industrial-designed areas, you can transform
            your body and smash your fitness goals your way.
          </p>
          <p>
            nXtLvL offers a range of training options: 1-on-1 personal training,
            Functional Fitness, Power Lifting, Olympic Lifting, High Intensity
            Interval (HIIT) training, and Body Building.
          </p>
          <p>
            Take a step into our retreat zone and you will find all the tools to
            enhance recovery and maintain wellness including infrared sauna,
            ozone filtered ice bath, hydrotherapy (enabling contrast therapy),
            Normatec Compression Boots and more.
          </p>
          <p>
            Make your way to the far end of our Centre and discover everything
            you need to boost that recovery from the inside out with our
            Supplement Store and Protein/Fuel Bar offering protein shakes and
            hydrogen water - the benefits of which are vast.
          </p>
          <p>
            nXtLvL is the home of the Region's first and only AWF (Australian
            Weightlifting Federation) accredited weightlifting club. From
            beginner through to advanced AWF Competitor we are the go to for all
            things Olympic Lifting and have access for remote athletes to be
            part of it as well as those local to the area.
          </p>
          <p>
            Grab a refreshment or coffee and catch up with friends before or
            after your workout.
          </p>
          <p>
            nXtLvL is where the Mid Coast meets their fitness goals while
            keeping training diverse, challenging, and fun!
          </p>
          <p>
            All are welcome at nXtLvL - click below to take us up on our offer
            of a 3 day free trial, or to arrange your grand tour with Sean & Kez
            here.
          </p>
        </div>
        <Link
          to="/membership/"
          className="block w-full max-w-xs px-4 py-4 mx-auto my-24 text-2xl font-bold leading-none tracking-widest text-center uppercase transition duration-300 ease-in-out border-2 border-white hover:bg-white hover:text-black focus:bg-white focus:text-black"
        >
          Sign up
        </Link>
      </div>
    </article>
  );
}

export { LevelUpYourFitness };
